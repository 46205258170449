<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */

import BurgerSmall from "../SVG/BurgerSmall.vue";
import Filters from "./Filters.vue";
import BaseModal from "@/components/regularComponents/UIComponents/Modals/BaseModal.vue";

defineProps<{
  floorRenderRef: HTMLElement | undefined;
  floorRenderSVGGroups: SVGGElement[] | undefined;
}>();

const isModalOpen = ref(false);
</script>

<template>
  <div class="floor-filters--mob d-flex j-btw al-center" @click="isModalOpen = true">
    <p class="text">{{ $t("filters") }}</p>
    <BurgerSmall />
  </div>

  <Teleport to="body">
    <Transition name="fade">
      <BaseModal
        is-height-full
        title="Filters"
        @close="isModalOpen = false"
        v-if="isModalOpen"
      >
        <Filters @close="isModalOpen = false" v-bind="{ ...$props }" />
      </BaseModal>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.floor-filters {
  &--mob {
    display: flex;
    background-color: $disabled;
    padding: 21.5px 24px;
    border-top: 1px solid $brightGray;
    border-bottom: 1px solid $brightGray;

    .text {
      color: $black;
      font-size: 14px;
      line-height: 17px;
      @include weight(600);
    }
  }
}
</style>
