<script setup lang="ts">
/**
 * @author Luka Tchigladze
 */

import { computed } from "vue";

import { useBuildingStore } from "@/store/building/index";

import CheckBox from "../SVG/CheckBox.vue";

// PROPS
const props = defineProps<{
  floorId: number;
}>();

// STORE
const buildingStore = useBuildingStore();

// COMPUTED
const checkBoxItems = computed(() => {
  return buildingStore?.checkBoxes?.find((x: any) => x.id === String(props?.floorId))?.items
});

// METHODS
const onCheck = (checkbox: any) => {
  checkbox.isChecked = !checkbox.isChecked;

  const flatId = checkbox.chkbox_flat_id;
  const flatNumber = checkbox.chkbox_flat_number;

  if (flatId && flatNumber) {
      buildingStore.toggleSelectedFlatsForBooking({
        flatId,
        flatNumber,
      });
  }
};
</script>

<template>
  <div v-for="chkbox in checkBoxItems" class="flat-select pos-abs" :class="{ 'is-selected': chkbox?.isChecked }" :style="{ top: chkbox?.coords?.top + 'px', left: chkbox?.coords?.left + 'px' }" :chkbox_flat_id="chkbox?.chkbox_flat_id" :chkbox_flat_number="chkbox?.chkbox_flat_number" :sold="chkbox?.sold" :reserved="chkbox?.reserved" @click="onCheck(chkbox)">
    <CheckBox />
  </div>
</template>
