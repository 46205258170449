import { useBuildingStore } from "@/store/building";
import type { Flat } from "@/store/building/types/StateType";



const changeToFlatsRoute = (event: Event) => {
    const router = useRouter();

    const currentTarget = event.currentTarget as HTMLElement;
    const currentTargetParent = currentTarget?.parentElement;
    const flatRoute = currentTargetParent?.getAttribute("route");

    if (!flatRoute) {
        console.warn("Flat route not found");
        return;
    }
    router.push(flatRoute);
};

const setChkBoxPos = (
    renderEl: Element,
    isDesktop: boolean,
    shapeGroup: SVGGElement,
    pinCircle: SVGGElement | undefined
) => {
    const coordz = {
        top: renderEl.getBoundingClientRect().top / (isDesktop ? 1 : 2.5),
        left: renderEl.getBoundingClientRect().left / (isDesktop ? 1 : 2.5),
    };
    if (!pinCircle) {
        return {
            top: shapeGroup.getBoundingClientRect().top / (isDesktop ? 1 : 2.5) - coordz.top,
            left: shapeGroup.getBoundingClientRect().left / (isDesktop ? 1 : 2.5) - coordz.left,
        }
    } else {
        return {
            top: pinCircle.getBoundingClientRect().top / (isDesktop ? 1 : 2.5) - coordz.top,
            left: pinCircle.getBoundingClientRect().left / (isDesktop ? 1 : 2.5) - coordz.left,
        }
    }
};

const addCheckBoxToPolygon = (
    renderEl: Element,
    isDesktop: boolean,
    shapeGroup: SVGGElement,
    matchedFlat: Flat | undefined,
    floorId: string,
) => {
    const store = useBuildingStore();

    // const groupSelectButton = shapeGroup.querySelector("circle");

    const isSold = matchedFlat?.conf.includes("sold");
    const isReserved = matchedFlat?.conf.includes("reserved");

    const pinCircles = shapeGroup.querySelectorAll<SVGGElement>(".circle-pin");
    const pinCircle = pinCircles?.[0];

    const floorIndex = store.checkBoxes?.findIndex((x: any) => x.id === floorId);

    const item = {
        chkbox_flat_id: matchedFlat?.id,
        chkbox_flat_number: matchedFlat?.flat_number,
        sold: isSold ? "1" : "0",
        reserved: isReserved ? "1" : "0",
        isChecked: floorIndex > -1 && store.checkBoxes?.[floorIndex]?.items?.some((x: any) => x.chkbox_flat_id === matchedFlat?.id),
        coords: setChkBoxPos(renderEl, isDesktop, shapeGroup, pinCircle),
    };


    if (floorIndex > -1) {
        const flatIndex = store.checkBoxes?.[floorIndex]?.items?.findIndex((x: any) => x.chkbox_flat_id === matchedFlat?.id);
        
        if (flatIndex > -1) {
            store.checkBoxes[floorIndex].items[flatIndex].coords = setChkBoxPos(renderEl, isDesktop, shapeGroup, pinCircle);
            return
        }
    
        store.checkBoxes?.[floorIndex]?.items?.push(item);
    } else {
        store.checkBoxes.push({
            id: floorId,
            items: [item],
        });
    }
};

const addResizeEvent = (renderEl: HTMLElement, isDesktop: boolean) => {
    window?.addEventListener("resize", () => {
        const shapeGroups = renderEl.querySelectorAll("g");
        shapeGroups.forEach((g) => {
            const pinCircles = g.querySelectorAll<SVGGElement>(".circle-pin");
            const pinCircle = pinCircles?.[0];
            const chkBox = renderEl.querySelector<HTMLElement>(
                `[chkbox_flat_id="${g?.getAttribute("flat_id")}"]`
            );

            if (chkBox) setChkBoxPos(renderEl, isDesktop, g, pinCircle);
        });
    });
};

export const setFloorDataOnShapes = (
    renderEl: HTMLElement | undefined,
    isDesktop: boolean,
    setCheckPositions: boolean,
): void => {
    // TODO! fix 'renderEl' value, use element with "display: block"
    if (!renderEl) {
        console.warn("Render not found in the DOM");
        return;
    }

    const store = useBuildingStore();

    if (setCheckPositions) {
        const floors = renderEl.querySelectorAll(
            ".building-module-floor__floor"
        );

        floors.forEach((floor) => {
            if (floor?.getAttribute("floor_id") != String(store.selectedFloor?.id)) return

            const shapeGroups = floor.querySelectorAll("g");

            shapeGroups.forEach((shapeGroup) => {
                const shapeGroupKey = shapeGroup.getAttribute("id");
                if (!shapeGroupKey) {
                    console.warn(`A shape is missing an ID key: ${shapeGroup}`);
                    return;
                }

                const shapePolygonData =
                    store?.selectedProjectFloors?.find(
                        (flr: any) =>
                            String(flr?.id) == floor?.getAttribute("floor_id")
                    )?.polygon_data[shapeGroupKey];
                if (!shapePolygonData) {
                    console.warn("No matching Polygon Data has been found");
                    return;
                }

                const matchedFlat = store?.selectedProjectFlats?.find(
                    (flat: Flat) => flat.id === shapePolygonData.id
                );

                const isSold = matchedFlat?.conf.includes("sold");
                const isReserved = matchedFlat?.conf.includes("reserved");

                if (
                    isSold ||
                    isReserved ||
                    store.selectedFloor?.floor_number !==
                    matchedFlat?.floor_number
                )
                    return;
                    
                addCheckBoxToPolygon(renderEl, isDesktop, shapeGroup, matchedFlat, floor?.getAttribute("floor_id") as string);
            });
        });

        return
    }

    setTimeout(() => {
        const floors = renderEl.querySelectorAll(
            ".building-module-floor__floor"
        );

        floors.forEach((floor) => {
            const shapeGroups = floor.querySelectorAll("g");
    
            if (!shapeGroups.length)
                return console.warn("Can't find 'shapeGroups'");

            shapeGroups.forEach((shapeGroup) => {
                const shapeGroupKey = shapeGroup.getAttribute("id");
                if (!shapeGroupKey) {
                    console.warn(`A shape is missing an ID key: ${shapeGroup}`);
                    return;
                }
    
                const shapePolygonData = store?.selectedProjectFloors?.find((flr: any) => String(flr?.id) == floor?.getAttribute("floor_id"))?.polygon_data[shapeGroupKey];
                if (!shapePolygonData) {
                    console.warn("No matching Polygon Data has been found");
                    return;
                }

                // console.log(store?.selectedProjectFlats, shapePolygonData.id);
    
                const matchedFlat = store?.selectedProjectFlats?.find(
                    (flat: Flat) => flat.id === shapePolygonData.id
                );

                if (!matchedFlat) {
                    console.warn(
                        `No matching flat found for this flat shape: ${shapeGroupKey}`
                    );
                    shapeGroup.setAttribute("sold", "1");
                    return;
                }
    
                const isSold = matchedFlat.conf.includes("sold");
                const isReserved = matchedFlat.conf.includes("reserved");
                const hidePrice = matchedFlat?.hide_price?.hide_price === 1;
    
                shapeGroup.setAttribute(
                    "number",
                    matchedFlat?.flat_number?.toString()
                );
                shapeGroup.setAttribute("flat_id", matchedFlat?.id?.toString());
                shapeGroup.setAttribute("price", matchedFlat?.price?.toString());
                shapeGroup.setAttribute("area", matchedFlat?.area_m2?.toString());
                shapeGroup.setAttribute("route", "/" + matchedFlat?.route);
                shapeGroup.setAttribute(
                    "flattype_id",
                    matchedFlat?.taxonomy?.flat_type?.[0]?.toString()!
                );
    
                shapeGroup.setAttribute("sold", isSold ? "1" : "0");
                shapeGroup.setAttribute("reserved", isReserved ? "1" : "0");
                shapeGroup.setAttribute("hide_price", hidePrice ? "1" : "0");
    
                if (isSold || isReserved) return;
                const groupPath = shapeGroup.querySelector("path");
                if (groupPath) {
                    groupPath.addEventListener("click", changeToFlatsRoute);
                }

                addCheckBoxToPolygon(renderEl, isDesktop, shapeGroup, matchedFlat, floor?.getAttribute("floor_id") as string);
            });
        });
    }, 1500);
    // }, 10);

    addResizeEvent(renderEl, isDesktop);
};