<script setup lang="ts">
/**
 * @author Giorgi Tseradze
 */

import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from "swiper";
import { Ref, ref, onBeforeMount, watch } from "vue";
import { useMainStore } from "@/store/main/index";
import FloorSVG from "@/components/regularComponents/BuildingModule/SVG/FloorSVG.vue";
import { useBuildingStore } from "@/store/building/index";
import { useRoute, useRouter } from "vue-router";
import FirstFloorSVG from "../SVG/FirstFloorSVG.vue";
import LastFloorSVG from "../SVG/LastFloorSVG.vue";

defineProps<{
  floorNumber: number;
}>();

interface Data {
  floorNumber: number | null | string;
  isFirst: boolean;
  isLast: boolean;
}

const emit = defineEmits(["floorChange"]);
const buildingStore = useBuildingStore();
const route = useRoute();
const router = useRouter();
const activeFloors = ref(0);
const path: Ref<string | undefined> = ref();
const anim = ref(true);
const mainStore = useMainStore();
const swiperInstance = ref();
const floorData: Ref<Data[]> = ref([]);

const routeFullPath = computed(() => route.fullPath);
let slideTimer: NodeJS.Timeout;

const getSwiperInstance = (sw: any) => {
  swiperInstance.value = sw;
  sw.on("slideChange", () => {
    emit("floorChange", swiperInstance?.value?.activeIndex);
    clearTimeout(slideTimer);
    slideTimer = setTimeout(() => {
      const floorNum = Number(
        floorData?.value?.[swiperInstance?.value.realIndex]?.floorNumber
      );
      const newRoute = route.path.replace(/floor-\d+/, `floor-${floorNum}`);
      router.push(newRoute);
    }, 500);
  });
};

watch(
  routeFullPath,
  () => {
    setTimeout(() => {
      if (typeof route.params.slugs == "string") return

      let floorNum: string | undefined;

      const slugs = route.params.slugs?.filter((x: any) => x?.length !== 2);

      if (buildingStore?.selectedProjectBlocks?.length && slugs?.[3]) {
        floorNum = slugs[3]?.split("-").at(-1);
      } else if (slugs?.[2]) {
        floorNum = slugs[2]?.split("-").at(-1);
      }

      const index = floorData?.value.findIndex((x) => x.floorNumber === Number(floorNum));
      if (swiperInstance.value && index !== swiperInstance.value?.activeIndex) {
        swiperInstance.value.slideTo(index, 400, true);
      }
    }, 500);
  },
  {
    deep: true,
    immediate: true,
  }
);
const handleFloors = () => {
  if (!buildingStore?.selectedProjectFloors) return;

  const blockSlug = route.params.slugs?.[2]?.split("-")?.[1]?.toLowerCase();

  const selectedBlockId = buildingStore.selectedProjectBlocks?.find(x => x.slug == blockSlug)?.id;

  buildingStore?.selectedProjectFloors.sort(
    (a, b) => Number(a.floor_number) - Number(b.floor_number)
  );

  let lastIndex: number;
  buildingStore?.selectedProjectFloors?.filter(floor => (!selectedBlockId || selectedBlockId == floor?.block_id))?.forEach((floor, index) => {
    if (
      (floor?.development_flats_status?.available ||
        floor?.development_flats_status?.reserved) &&
      floor?.conf !== "sold"
    ) {
      lastIndex = index;
    }
  });

  buildingStore?.selectedProjectFloors?.filter(floor => (!selectedBlockId || selectedBlockId == floor?.block_id))?.forEach((floor, index) => {
    if (
      (floor?.development_flats_status?.available ||
        floor?.development_flats_status?.reserved) &&
      floor?.conf !== "sold"
    ) {
      activeFloors.value++;
      if (activeFloors?.value === 1) {
        floorData?.value?.push({
          floorNumber: floor?.floor_number,
          isFirst: true,
          isLast: false,
        });
      }
      if (activeFloors?.value > 1 && index < lastIndex) {
        floorData?.value?.push({
          floorNumber: floor?.floor_number,
          isFirst: false,
          isLast: false,
        });
      }
      if (index === lastIndex && activeFloors?.value !== 1) {
        floorData?.value?.push({
          floorNumber: floor?.floor_number,
          isFirst: false,
          isLast: true,
        });
      }
    }
  });
};

onBeforeMount(() => {
  if (buildingStore?.selectedProjectBlocks?.length) {
    const path4 = route?.params?.slugs?.[3];
    if (path4 && typeof path4 === "string") {
      path.value = path4.split("-").at(-1);
    }
  } else {
    const path3 = route?.params?.slugs?.[2];
    if (path3 && typeof path3 === "string") {
      path.value = path3.split("-").at(-1);
    }
  }

  handleFloors();
});
</script>

<template>
  <div class="floor-slider pos-rel">
    <div class="floor-slider__active pos-abs"></div>
    
    <Swiper
      :slidesPerView="'auto'"
      @swiper="getSwiperInstance"
      :key="mainStore?.windowWidth"
      :speed="400"
      :direction="'vertical'"
      :initialSlide="path ? Number(path) - 1 : 0"
      :grabCursor="true"
      :loop="false"
      :updateOnWindowResize="true"
      :centeredSlides="true"
      :freeMode="{
        enabled: true,
        sticky: true,
        minimumVelocity: 2,
        momentumBounce: false,
      }"
      :modules="[FreeMode]"
    >
      <SwiperSlide
        v-for="(item, index) in floorData"
        :key="index"
        :floorNum="item?.floorNumber"
      >
        <div class="floor-slider__card d-flex pos-rel" :class="{ floor__anim: anim }">
          <FirstFloorSVG
            v-if="item?.isFirst"
            class="floor-slider__card-first img-absolute"
          />
          <FloorSVG class="floor-slider__card-svg img-absolute" />
          <div class="d-flex dir-col al-center pos-abs">
            <h3>{{ item?.floorNumber }}</h3>
            <p class="p-12 uppercase">{{ $t("floor") }}</p>
          </div>
          <LastFloorSVG
            v-if="item?.isLast"
            class="floor-slider__card-last img-absolute"
          />
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<style lang="scss">
.floor-slider {
  flex-shrink: 0;
  width: 12.285714285714286%;
  border-right: 1px solid $brightGray;
  max-height: 100vh;
  overflow: hidden;

  @include maxq(desktop) {
    width: 14.803149606299213%;
  }
  @include maxq(desktop-sm) {
    width: 17.479674796747968%;
  }
  @include maxq(tablet) {
    height: calc(100vh - 89px);
    background-color: white;
  }
  &::before {
    content: "";
    width: 100%;
    height: 14.83050847457627%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    z-index: 2;

    @include maxq(desktop-sm) {
      height: 143px;
    }

    @include maxq(tablet) {
      height: 94px;
    }
  }
  &__active {
    display: none;
    left: 48px;
    top: 50%;
    border-bottom: 2px solid $black;
    width: 200px;
    height: 1px;
    @include maxq(desktop-lg) {
      width: 56px;
    }
    @include maxq(desktop) {
      left: 32px;
    }
    @include maxq(desktop-sm) {
      left: 16px;
    }
  }
  &__card {
    display: flex;
    position: relative;
    margin-left: 48px;
    width: 150px;
    height: 300px;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    @include maxq(desktop-2k) {
      width: 100px;
      height: 200px;
    }
    @include maxq(desktop-lg) {
      width: 48px;
      height: 100px;
    }
    @include maxq(desktop) {
      margin-left: 32px;
    }
    @include maxq(desktop-sm) {
      margin-left: 16px;
    }
    &-first {
      top: -1490px !important;
      left: 0 !important;
      width: 150px !important;
      height: 1500px !important;
      @include maxq(desktop-2k) {
        top: -990px !important;
        left: 0 !important;
        width: 100px !important;
        height: 1000px !important;
      }
      @include maxq(desktop-lg) {
        top: -490px !important;
        left: 0 !important;
        width: 50px !important;
        height: 500px !important;
      }
    }
    &-last {
      top: 170px !important;
      left: 0 !important;
      width: 150px !important;
      height: 1500px !important;
      @include maxq(desktop-2k) {
        top: 120px !important;
        left: 0 !important;
        width: 100px !important;
        height: 1000px !important;
      }

      @include maxq(desktop-lg) {
        top: 55px !important;
        left: 0 !important;
        width: 50px !important;
        height: 500px !important;
      }
    }
    &-svg {
      width: 100%;
      height: 100%;
    }
    div {
      top: 250px;
      left: 220px;
      @include maxq(desktop-2k) {
        top: 160px;
        left: 150px;
      }
      @include maxq(desktop-lg) {
        top: 75px;
        left: 80px;
      }
      @include maxq(desktop) {
        margin-left: 32px;
        left: 40px;
      }
      @include maxq(desktop-sm) {
        margin-left: 16px;
        left: 55px;
      }
      h3 {
        font-size: 44px;
        @include weight(300);
        line-height: 26px;
        opacity: 0.6 !important;
        @include maxq(desktop-2k) {
          font-size: 30px;
        }
        @include maxq(desktop-lg) {
          line-height: 26px !important;
          font-size: 18px;
        }
      }
      p {
        display: none;
        opacity: 0.6 !important;
        line-height: 32px;
        @include maxq(desktop-lg) {
          font-size: 12px;
          line-height: 18px;
        }
        @include maxq(desktop) {
          line-height: 12px;
        }
      }
    }
  }

  .swiper {
    display: flex;
    overflow: visible;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include maxq(desktop-2k) {
      height: 200px;
    }
    @include maxq(desktop-lg) {
      height: 100px;
    }
    &-wrapper {
      height: 300px;
      @include maxq(desktop-2k) {
        height: unset;
      }
    }
    .swiper-slide {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .swiper-slide-active {
      .floor-slider__card {
        div {
          top: 210px !important;
          left: 180px !important;
          @include maxq(desktop-2k) {
            top: 130px !important;
            left: 110px !important;
          }
          @include maxq(desktop-lg) {
            top: 65px !important;
            left: 70px !important;
          }
          @include maxq(desktop) {
            top: 58px !important;
            left: 30px !important;
          }
          @include maxq(desktop-sm) {
            top: 58px !important;
            left: 50px !important;
          }
        }
        h3 {
          @include headline-2;
          @include weight(300);
          opacity: 1;

          @include maxq(desktop-2k) {
            font-size: 36px;
            line-height: 42px;
          }

          @include maxq(desktop-lg) {
            @include headline-2;
            @include weight(300);
          }
        }
        p {
          display: flex;
          opacity: 0;
        }
        &.floor__anim {
          .floor-slider__card-svg {
            path:nth-child(1) {
              display: inline-block;
              width: 100px;
              fill: black;
              width: 100px;
              @include easeOut(300, all);
            }
          }
          h3 {
            transform: scale(1.5);
            @include easeOut(300, all);
          }
          p {
            margin-top: 14px;
            opacity: 0.6 !important;
            @include easeOut(1000, opacity);
          }
        }
      }
    }
  }
}
</style>
