<template>
  <div class="d-flex">
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="10" height="1" fill="#222222" />
      <rect y="4.5" width="10" height="1" fill="#222222" />
      <rect y="8.5" width="10" height="1" fill="#222222" />
    </svg>
  </div>
</template>
