<script setup lang="ts">
/**
 * @author Irakli Kandelaki
 */

/**
 * @summary Floor render module.
 */

/** IMPORT VUE HOOKS */
import {
  ref,
  onMounted,
  // onBeforeUnmount,
  computed,
  onBeforeMount,
  watch,
  nextTick,
} from "vue";

/** IMPORT TYPES */
import type { Ref } from "vue";
import type { Props } from "./parts/FlatSummary.vue";
import type { TypeViews } from "./parts/ts/interfaces";
import { useRoute, useRouter } from "vue-router";

import { getTaxonomySS } from "@/composables/core/func";

/** IMPORT STORE */
import { useBuildingStore } from "@/store/building/index";
import { useMainStore } from "@/store/main/index";

/** IMPORT CORE FUNCTIONS */
import { setFloorDataOnShapes } from "./core/shapeSelectHandler";
import touchZoomModule from "./core/touchZoomModule";

/** IMPORT COMPONENTS */
import FloorSlider from "@/components/regularComponents/BuildingModule/parts/FloorSlider.vue";
import Filters from "./parts/Filters.vue";
import FiltersWrapperMob from "./parts/FiltersWrapperMob.vue";
import FlatSummary from "./parts/FlatSummary.vue";
import ViewTypes from "./parts/ViewTypes.vue";
import CheckBoxItems from "./parts/CheckBoxItems.vue";
import BaseModal from "@/components/regularComponents/UIComponents/Modals/BaseModal.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";
import ArrowIcon from "./SVG/ArrowIcon.vue";
import Loading from "./SVG/Loading.vue";

/** DECLARE STORE */
const buildingStore = useBuildingStore();

const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();

/** REACTIVE DATA */
const floorRender: Ref<HTMLElement | undefined> = ref();
const summaryData = ref<Partial<Props>>();
const isLoading: Ref<boolean> = ref(false);

let renderMutationObserver: MutationObserver;
const floorRenderSVGGroups: Ref<SVGGElement[] | undefined> = ref([]);

const isDesktop = computed(() => mainStore.windowWidth > mainStore.breakpoints.tablet);

const isMobile = computed(() => mainStore.windowWidth <= mainStore.breakpoints.mobile);

const floorNumberMin = computed(() => {
  const floorNumbers =
    buildingStore?.selectedProjectFloors
      ?.filter((x) => x?.development_flats_status?.available && x?.conf != "sold")
      ?.map((x) => x.floor_number) || [];

  return Math.min(...floorNumbers);
});

const floorNumberMax = computed(() => {
  const floorNumbers =
    buildingStore?.selectedProjectFloors
      ?.filter((x) => x?.development_flats_status?.available && x?.conf != "sold")
      ?.map((x) => x.floor_number) || [];

  return Math.max(...floorNumbers);
});

const typeViews = computed(() => {
  const result: TypeViews = {
    right: [],
    left: [],
    top: [],
    bottom: [],
  };

  const storeTypeViews = mainStore.terms?.type_view;
  const taxonomies = buildingStore.selectedProject?.taxonomy.type_view;

  taxonomies?.forEach((el) => {
    const match = storeTypeViews?.find((x) => x.id === el);
    if (!match) return;

    const side = match?.side || "right";
    result[side].push({
      title: match.content_title,
      svg: match.icon.light,
    });
  });

  return result;
});

const onMouseEnter = (e: MouseEvent | null, trg?: SVGElement) => {
  const target = e ? (e.target as SVGGElement) : trg;
  const groups = document.querySelectorAll(".building-module-floor__render g");
  const chkBoxes = floorRender.value?.querySelectorAll<HTMLElement>(".flat-select");
  chkBoxes?.forEach((box) => {
    box.classList.remove("is-visible");
  });
  groups.forEach((g) => {
    g.classList.remove("hovered-flat");
  });

  target?.classList.add("hovered-flat");

  const isSold = target?.getAttribute("sold") == "1";
  const isReserved = target?.getAttribute("reserved") == "1";
  const hidePrice = target?.getAttribute("hide_price") == "1";

  const chkBox = floorRender.value?.querySelector<HTMLElement>(
    `[chkbox_flat_id="${target?.getAttribute("flat_id")}"]`
  );

  if (chkBox) {
    if (isDesktop.value) {
      chkBox.classList.add("is-visible");
    }
  }

  summaryData.value = {};

  summaryData.value.number = target?.getAttribute("number");
  summaryData.value.price = isSold || isReserved ? null : target?.getAttribute("price");
  summaryData.value.isSold = isSold;
  summaryData.value.isReserved = isReserved;
  summaryData.value.id = Number(target?.getAttribute("flat_id"));
  summaryData.value.hidePrice = hidePrice;
  summaryData.value.space = target?.getAttribute("area");
  summaryData.value.route = target?.getAttribute("route");
};

const onMouseLeave = () => {
  if (isDesktop.value) {
    summaryData.value = undefined;
    const groups = document.querySelectorAll(".building-module-floor__render g");
    groups.forEach((g) => {
      g.classList.remove("hovered-flat");
    });
    const chkBoxes = floorRender.value?.querySelectorAll<HTMLElement>(".flat-select");
    chkBoxes?.forEach((box) => {
      box.classList.remove("is-visible");
    });
  }
};

const goToFlat = () => {
  const flatRoute = summaryData.value?.route;
  if (!flatRoute) {
    console.warn("Flat route not found");
    return;
  }

  if (!summaryData.value?.isSold && !summaryData.value?.isReserved) {
    router.push(flatRoute);
  }
};

const changeFloor = (direction: string) => {
  let selectFloorNumber = buildingStore.selectedFloor?.floor_number;
  if (!buildingStore.selectedProjectFloors?.length) return;

  if (direction === "bottom") {
    for (let i = buildingStore.selectedProjectFloors.length - 1; i >= 0; i--) {
      if (
        buildingStore.selectedProjectFloors[i].floor_number < Number(selectFloorNumber) &&
        buildingStore.selectedProjectFloors[i]?.development_flats_status?.available &&
        buildingStore.selectedProjectFloors[i].conf != "sold"
      ) {
        selectFloorNumber = buildingStore.selectedProjectFloors[i].floor_number;

        break;
      }
    }
  } else {
    for (let i = 0; i < buildingStore.selectedProjectFloors.length; i++) {
      if (
        buildingStore.selectedProjectFloors[i].floor_number > Number(selectFloorNumber) &&
        buildingStore.selectedProjectFloors[i]?.development_flats_status?.available &&
        buildingStore.selectedProjectFloors[i].conf != "sold"
      ) {
        selectFloorNumber = buildingStore.selectedProjectFloors[i].floor_number;

        break;
      }
    }
  }

  if (!selectFloorNumber) return;

  if (selectFloorNumber < floorNumberMin.value) {
    return;
  }

  if (selectFloorNumber > floorNumberMax.value) {
    return;
  }

  const newRoute = route.path.replace(/floor-\d+/, `floor-${selectFloorNumber}`);
  router.push(newRoute);
};

const floorNumber = computed(() => {
  if (buildingStore?.selectedFloor?.floor_number) {
    return buildingStore?.selectedFloor?.floor_number;
  } else if (route.params?.slugs?.length) {
    const floorPath = buildingStore.selectedProjectBlocks?.length
      ? (route.params?.slugs as string[])?.filter((x: any) => x?.length !== 2)?.[3]
      : (route.params?.slugs as string[])?.filter((x: any) => x?.length !== 2)?.[2];

    if (typeof floorPath === "string") {
      return Number(floorPath?.split("-")?.[1] || "1");
    }
  } else {
    return 1;
  }
});

const hilightCheckboxes = () => {
  buildingStore.selectedFlatsForBooking?.forEach((el) => {
    const matchedEl = document.querySelector(
      `.building-module-floor__render-wrapper-in .flat-select[chkbox_flat_id="${el.flatId}"]`
    );

    if (matchedEl) {
      matchedEl?.classList?.add("is-selected");
    }
  });
};

const toggleFlatsForBooking = () => {
  const flatId = Number(summaryData.value?.id);
  const flatNumber = Number(summaryData.value?.number);
  let added = false;

  if (flatId && flatNumber) {
    added = buildingStore.toggleSelectedFlatsForBooking({
      flatId,
      flatNumber,
    });

    const matchedEl = document.querySelector(
      `.building-module-floor__render-wrapper-in .flat-select[chkbox_flat_id="${summaryData.value?.id}"]`
    );

    if (!matchedEl) return;

    if (added && !isDesktop.value) {
      matchedEl?.classList?.toggle("is-selected");
    }
  }
};

watch(route, async () => {
  (() => {
    const blockSlug = route.params.slugs?.[2]?.split("-")?.[1]?.toLowerCase();

    const selectedBlockId = buildingStore.selectedProjectBlocks?.find(x => x.slug == blockSlug)?.id;

    buildingStore.selectedFloor = buildingStore.selectedProjectFloors?.find(
      (floorItem) => floorItem?.floor_number === Number(floorNumber.value) && (!selectedBlockId || selectedBlockId == floorItem.block_id)
    );

    /**
     * update oldProjectId
     */

    if (!buildingStore.oldProjectId) {
      buildingStore.oldProjectId = buildingStore.selectedProject?.id;
    } else if (buildingStore.oldProjectId != buildingStore.selectedProject?.id) {
      buildingStore.oldProjectId = buildingStore.selectedProject?.id;
      buildingStore.selectedFlatsForBooking = [];
    }

    buildingStore.selectedProjectRenderSvg = buildingStore.getSelectedProjectRenderSvg;
    buildingStore.selectedProjectRenderImage =
      buildingStore.getSelectedProjectRenderImage;
    buildingStore.selectedProjectRenderImageMobile =
      buildingStore.getSelectedProjectRenderImageMobile;
    if (!buildingStore.selectedProject?.polygon_data) {
      console.warn("No shapes have data assigned!");
      return;
    } else buildingStore.selectedProject.polygonDataToArray = [];

    for (const val of Object.values(buildingStore.selectedProject.polygon_data)) {
      buildingStore.selectedProject.polygonDataToArray.push(val);
    }
    if (buildingStore?.testResponse?.data?.blocks?.list?.length) {
      buildingStore.selectedProjectBlocks =
        buildingStore?.testResponse?.data?.blocks?.list;
      buildingStore.setSelectedProjectBlockValues();
    }

    if (!buildingStore?.testResponse?.data?.floors?.list?.length) {
      console.warn("Floors are not attached to the project!");
      return;
    } else {
      buildingStore.selectedProjectFloors = buildingStore?.testResponse.data.floors.list.sort(
        (a: any, b: any) => a.floor_number - b.floor_number
      );
    }

    buildingStore.setSelectedProjectFloorValues();
  })();

  summaryData.value = undefined;
  /**
   * Remove ".flat-select" checkboxs form svg render, on route change
   */
  // document
  //   .querySelectorAll(".building-module-floor__render-wrapper-in .flat-select")
  //   .forEach((el) => el.remove());

  // isLoading.value = true;
  await nextTick();
  // setTimeout(() => {
  setTimeout(() => {
    setFloorDataOnShapes(floorRender.value, isDesktop.value, true);
  }, 500);
  //   floorRenderSVGGroups.value = floorRender.value?.querySelectorAll("svg g") as
  //     | SVGGElement[]
  //     | undefined;

  //   floorRenderSVGGroups.value?.forEach((g) => {
  //     if (!isDesktop.value) {
  //       g.addEventListener("pointerenter", onMouseEnter);
  //       g.addEventListener("pointerleave", onMouseLeave);
  //     } else {
  //       g.addEventListener("mouseenter", onMouseEnter);
  //       g.addEventListener("mouseleave", onMouseLeave);
  //     }
  //   });
  //   // isLoading.value = false;
  // }, 500);

  if (!isDesktop.value) {
    setTimeout(() => {
      let found = false;
      const wrapper = document.querySelector(
        `.building-module-floor__floor[floor_id='${buildingStore.selectedFloor?.id}']`
      );

      floorRenderSVGGroups.value?.forEach((g) => {
        if (
          wrapper?.contains(g) &&
          !found &&
          g.getAttribute("sold") !== "1" &&
          g.getAttribute("reserved") !== "1"
        ) {
          found = true;
          onMouseEnter(null, g);
        }
      });
    }, 1500);
  }

  if (!isDesktop.value) {
    setTimeout(() => {
      hilightCheckboxes();
    }, 2500);
  }
});

watch(
  () => mainStore?.lang,
  () => {
    mainStore.setTaxonomy("type_view");
  }
);

onMounted(() => {
  setTimeout(async () => {
    await setFloorDataOnShapes(floorRender.value, isDesktop.value, false);
    floorRenderSVGGroups.value = floorRender.value?.querySelectorAll("svg g") as
      | SVGGElement[]
      | undefined;

    // const options = { subtree: true, childList: true, attributes: true };
    // renderMutationObserver = new MutationObserver(() => setFloorDataOnShapes);
    // renderMutationObserver.observe(floorRender.value as Node, options);

    floorRenderSVGGroups.value?.forEach((g) => {
      if (!isDesktop.value) {
        g?.addEventListener("pointerenter", onMouseEnter);
        g?.addEventListener("pointerleave", onMouseLeave);
      } else {
        g?.addEventListener("mouseenter", onMouseEnter);
        g?.addEventListener("mouseleave", onMouseLeave);
      }
    });
  }, 2000);

  if (!isDesktop.value) {
    setTimeout(() => {
      let found = false;
      const wrapper = document.querySelector(
        `.building-module-floor__floor[floor_id='${buildingStore.selectedFloor?.id}']`
      );

      floorRenderSVGGroups.value?.forEach((g) => {
        if (
          wrapper?.contains(g) &&
          !found &&
          g.getAttribute("sold") !== "1" &&
          g.getAttribute("reserved") !== "1"
        ) {
          found = true;
          onMouseEnter(null, g);
        }
      });
      if (floorRender.value) {
        touchZoomModule(floorRender.value);
      }
    }, 4000);
  }

  setTimeout(() => {
    isLoading.value = false;
  }, 3500);

  if (!isDesktop.value) {
    setTimeout(() => {
      hilightCheckboxes();
    }, 4000);
  }
});

onBeforeMount(async () => {
  isLoading.value = true;
});

// onBeforeUnmount(() => {
//   if (!renderMutationObserver) return;
//   floorRenderSVGGroups.value?.forEach((g) => {
//     if (!isDesktop.value) {
//       g.removeEventListener("pointerenter", onMouseEnter);
//       g.removeEventListener("pointerleave", onMouseLeave);
//     } else {
//       g.removeEventListener("mouseenter", onMouseEnter);
//       g.removeEventListener("mouseleave", onMouseLeave);
//     }
//   });
//   renderMutationObserver.disconnect();
// });
</script>

<template>
  <section class="building-module-floor bg-white pos-rel">
    <FloorSlider v-if="mainStore?.windowWidth > 983" :floorNumber="floorNumber || 0" />

    <div class="building-module-floor__render-wrapper pos-rel">
      <FiltersWrapperMob
        :floorRenderRef="floorRender"
        :floorRenderSVGGroups="floorRenderSVGGroups"
      />

      <div
        class="d-flex building-module-floor__render-wrapper-in"
        :style="{
          height: mainStore.windowWidth <= 983 ? mainStore.windowHeight - 152 + 'px' : '',
        }"
      >
        <Button
          v-if="
            !isDesktop &&
            summaryData?.id &&
            !Boolean(summaryData?.isSold || summaryData?.isReserved)
          "
          class="select-flat-button"
          :btn-type="9"
          :btnText="
            buildingStore.selectedFlatsForBooking?.some(
              (el) => el.flatId === summaryData?.id
            )
              ? 'remove flat'
              : 'select flat'
          "
          @click="toggleFlatsForBooking"
        />

        <ViewTypes
          class="type-view type-view--top"
          v-if="typeViews.top?.length"
          :typeViews="typeViews.top"
        />

        <div class="first">
          <div class="first-in">
            <ViewTypes v-if="typeViews.left?.length" :typeViews="typeViews.left" />

            <div class="flat-summary-wrapper">
              <div class="flat-summary-wrapper-in d-flex">
                <div
                  v-if="isMobile"
                  class="projects-render__tooltip"
                  :class="{
                    'projects-render__tooltip--down-disabled':
                      floorNumber == floorNumberMin,
                    'projects-render__tooltip--up-disabled':
                      floorNumber == floorNumberMax,
                  }"
                >
                  <div class="projects-render__tooltip-left d-flex dir-col j-center">
                    <div class="projects-render__tooltip-left-in d-flex">
                      <div
                        class="projects-render__tooltip-left-col d-flex dir-col j-center"
                      >
                        <h3 class="no-flicker">{{ floorNumber }}</h3>
                        <span class="p-14 uppercase no-flicker">{{ $t("floor") }}</span>
                      </div>

                      <div class="projects-render__tooltip-right-col d-flex dir-col">
                        <div
                          @click="changeFloor('top')"
                          class="projects-render__tooltip-arrow d-flex j-center al-center pointer"
                        >
                          <ArrowIcon />
                        </div>
                        <div
                          @click="changeFloor('bottom')"
                          class="projects-render__tooltip-arrow d-flex j-center al-center pointer"
                        >
                          <ArrowIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Transition name="fade">
                  <FlatSummary v-if="summaryData" :data="summaryData" />
                </Transition>
              </div>

              <div></div>

              <Button
                v-if="!isDesktop"
                @click="goToFlat"
                :class="{
                  disabled: summaryData?.isSold || summaryData?.isReserved,
                }"
                :isDisabled="Boolean(summaryData?.isSold || summaryData?.isReserved)"
                :btn-type="9"
                btnText="show apartment"
              />
            </div>
          </div>
        </div>

        <div class="center pos-rel w-full h-fit" ref="floorRender">
          <!-- APPEND ALL FLOOR DATA HERE -->
          <div
            v-for="(floor, index) in buildingStore?.selectedProjectFloors"
            :floor_id="floor.id"
            :key="index"
            class="building-module-floor__floor"
            v-show="buildingStore?.selectedFloor?.id == floor?.id"
          >
            <div
              class="building-module-floor__render"
              :class="{ 'building-module-floor__render--loaded': !isLoading }"
              v-html="floor?.polygon_svg"
            />

            <Transition name="fade">
              <img
                v-show="!isLoading"
                class="pos-abs"
                :src="floor?.polygonImage?.[0]?.devices?.desktop"
                :alt="buildingStore?.selectedProject?.title"
              />
            </Transition>

            <CheckBoxItems :floorId="floor?.id" />
          </div>

          <!-- <div
            class="building-module-floor__render"
            :class="{ 'building-module-floor__render--loaded': !isLoading }"
            v-html="buildingStore?.selectedFloor?.polygon_svg"
          /> -->

          <!-- <Transition name="fade">
            <img
              v-show="!isLoading"
              class="pos-abs"
              :src="buildingStore?.selectedFloor?.polygonImage?.[0]?.devices?.desktop"
              :alt="buildingStore?.selectedProject?.title"
            />
          </Transition> -->

          <Transition name="fade">
            <BaseModal class="loading-modal" v-if="isLoading">
              <div class="loading-modal__content d-flex dir-col j-center al-center">
                <Loading />

                <div class="d-flex al-center">
                  <span> Loading </span>
                  <div class="dot-flashing"></div>
                </div>
              </div>
            </BaseModal>
          </Transition>
        </div>

        <div class="last">
          <div class="last-in">
            <ViewTypes v-if="typeViews.right?.length" :typeViews="typeViews.right" />
            
            <div class="flat-summary-wrapper">
              <div class="flat-summary-wrapper-in d-flex"></div>
            </div>
          </div>
        </div>

        <ViewTypes
          class="type-view type-view--bottom"
          v-if="typeViews.bottom?.length"
          :typeViews="typeViews.bottom"
        />
      </div>
    </div>

    <Filters
      :onlyProjects="false"
      :floorRenderRef="floorRender"
      :floorRenderSVGGroups="floorRenderSVGGroups"
      class="floor-filters--desktop floor-filters--inner"
    />
  </section>
</template>

<style lang="scss">
.building-module-floor {
  @keyframes loading {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes dot-flashing {
    0% {
      background-color: $black;
    }

    50%,
    100% {
      background-color: rgba($black, 0.2);
    }
  }

  display: flex;
  &__render {
    font-size: 0;
    height: fit-content;
    &-wrapper {
      width: 100%;
      margin-top: 140px;

      @include maxq(desktop) {
        margin-top: 178px;
      }

      @include maxq(tablet) {
        margin-top: 92px;
        overflow: hidden;
      }

      @include maxq(mobile) {
        margin-top: unset;
        // height: calc(100vh - 268px);
        height: fit-content;
      }

      &-in {
        height: 100%;
        padding: 0 48px;
        align-items: center;

        .select-flat-button {
          position: absolute;
          top: 72px;
          z-index: 10;
        }

        @include maxq(tablet) {
          padding: 0 32px;
          overflow: hidden;
        }

        @include maxq(mobile) {
          padding: 0 16px;
          height: calc(100% - 152px);
        }

        .view {
          @include maxq(mobile) {
            & > div {
              display: none;
            }
          }

          svg {
            width: 100%;
            height: 90px;

            @include maxq(desktop-2k) {
              height: 56px;
            }

            @include maxq(desktop-lg) {
              width: initial;
              height: initial;
            }
          }

          .text {
            font-size: 30px;

            @include maxq(desktop-2k) {
              font-size: 18px;
            }

            @include maxq(desktop-lg) {
              font-size: 12px;
            }
          }
        }

        .type-views {
          @include maxq(tablet) {
            z-index: 10;
          }
          @include maxq(mobile) {
            gap: 90px;
          }
        }

        .first,
        .last {
          height: 100%;

          @include maxq(mobile) {
            width: 30px;
          }

          .view {
            @include maxq(mobile) {
              .text {
                transform: rotate(-90deg);
              }
            }
          }

          &-in {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .type-views {
            height: 100%;
            justify-content: flex-end;

            @include maxq(tablet) {
              justify-content: center;
            }

            @include maxq(mobile) {
              width: 30px;
              height: calc(100% - 239px);
              align-items: center;
              position: absolute;
              top: 62px;
            }
          }

          .flat-summary {
            align-self: flex-end;

            @include maxq(mobile) {
              gap: 0;
              flex-basis: calc(100% - 124px);
            }

            @include maxq(mobile-sm) {
              flex-basis: calc(100% - 122px);
              min-height: auto;
              height: 80px;
            }

            &-wrapper {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              .button {
                &.disabled {
                  span {
                    color: $black;
                  }
                }
              }

              @include maxq(tablet) {
                min-height: 115px;
                justify-content: space-between;
                align-items: center;
                height: auto;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                flex-direction: row;
                padding: 16px;
                border-top: 1px solid $brightGray;
                background-color: $white;
                z-index: 3;
              }

              @include maxq(mobile) {
                flex-direction: column;
                align-items: flex-start;
                z-index: 10;

                .button {
                  width: 100%;
                  margin-top: 16px;
                }
              }

              @include maxq(mobile-sm) {
                padding: 8px;
              }

              &-in {
                @include maxq(mobile) {
                  width: 100%;
                }

                @include maxq(mobile-sm) {
                  gap: 8px;
                }
              }
            }
          }
        }

        .center {
          width: 83%;
          @include maxq(desktop-lg) {
            width: 100%;
          }
          // @include easeOut(50, all);
          @include maxq(tablet) {
            transform: scale(2.5);
          }
          @include maxq(mobile) {
            position: relative;
            top: -80px;
          }
        }

        .last {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;

          @include maxq(tablet) {
            position: absolute;
            right: 32px;
          }

          @include maxq(mobile) {
            width: 30px;
            height: calc(100% - 62px);
            top: 62px;
            right: 16px;
          }

          .view {
            @include maxq(mobile) {
              .text {
                transform: rotate(90deg);
              }
            }
          }

          .type-views {
            align-items: flex-end !important;

            @include maxq(mobile) {
              align-items: center !important;
              height: calc(100% - 177px);
              top: 0;
            }
          }

          .flat-summary-wrapper {
            @include maxq(tablet) {
              display: none;
            }
          }
        }
      }
    }
    svg {
      position: relative;
      z-index: 2;
    }
    circle {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      // &:nth-child(2) {
      //   position: relative;
      //   cursor: pointer;
      //   opacity: 1;
      //   visibility: visible;
      //   pointer-events: all;
      //   stroke: $mango;
      //   stroke-width: 2px;
      //   fill: $white;
      //   z-index: 90;
      //   @include easeOut(500, all);
      //   r: 14;
      //   @include hover {
      //     fill: $mango;
      //   }
      //   &.is-selected {
      //     fill: $mango;
      //   }
      // }
    }

    path {
      cursor: pointer;
      opacity: 1;
      fill: rgba($apHover, 0);
      stroke: $apHover;
      stroke-width: 0.5px;

      @include easeOut(400, all);
      &:hover {
        fill: rgba($apHover, 0.6);
      }
      // @include hover {
      //   fill: rgba($apHover, 0.6);
      // }
    }
    g {
      position: relative;
    }
    // .selected-flat {
    //   opacity: 0;
    //   visibility: hidden;
    //   pointer-events: none;
    // }

    g.hovered-flat path,
    g.selected-flat path {
      fill: rgba($apHover, 0.6);
    }

    g[reserved="1"] path {
      fill: rgba($reservedApHover, 0.6);
      // circle:nth-child(2) {
      //   opacity: 0;
      //   visibility: hidden;
      //   pointer-events: none;
      // }
    }

    g[sold="1"] {
      path {
        fill: rgba($sold, 0.6);
      }
    }

    &--loaded {
      g:not([flat_id]) path,
      g:not([flat_id]) path {
        fill: rgba($sold, 0.6);
      }
    }
  }
  .flat-select {
    cursor: pointer;
    opacity: 0;
    z-index: 90;
    display: flex;
    @include easeOut(500, all);

    svg {
      --w: 40px;

      width: var(--w);
      height: var(--w);

      @include maxq(desktop-2k) {
        --w: 32px;
      }

      @include maxq(desktop-lg) {
        --w: 20px;
      }

      @include maxq(tablet) {
        --w: 8px;
      }
    }

    circle {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      stroke: none;
      fill: white;
      pointer-events: all;
    }
    path {
      pointer-events: all;
      opacity: 1;
      fill: $black;
      stroke: none;
      // stroke-width: 1.2px;
      transition: 400ms cubic-bezier(0.33, 1, 0.68, 1) all;
    }
    &.is-visible {
      opacity: 1;
    }
    @include hover {
      opacity: 1;
    }
    &.is-selected {
      opacity: 1 !important;
      circle {
        fill: $black;
      }
      path {
        fill: $mangoDark;
      }
    }
  }
  img {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include maxq(mobile) {
      top: 0px;
      left: 0;
    }
  }

  .floor-filters {
    &--desktop {
      @include maxq(mobile) {
        display: none;
      }
    }
    &--mob {
      display: none;
      @include maxq(mobile) {
        position: relative;
        z-index: 19;
        display: flex;
      }
    }

    .burgerdropdown {
      height: 100%;
    }
  }

  .projects-render__tooltip {
    gap: 16px;
    @include maxq(mobile) {
      transform: none !important;
      opacity: 1 !important;
      padding: 0 !important;
    }

    @include maxq(mobile-sm) {
      width: 35%;
    }
    h3 {
      font-size: 96px;
      line-height: 100px;
      @include weight(300);
      @include maxq(desktop-2k) {
        font-size: 64px;
        line-height: 70px;
      }
      @include maxq(desktop-lg) {
        font-size: 48px;
        line-height: 55px;
      }
      @include maxq(tablet) {
        font-size: 32px;
        line-height: 26px;
      }
      @include maxq(mobile-sm) {
        font-size: 24px;
      }
    }
    &-left {
      @include maxq(tablet) {
        margin-right: 16px;
      }

      @include maxq(mobile) {
        margin-right: 0;
      }

      @include maxq(mobile-sm) {
        margin-right: 8px;
        justify-content: flex-start;
      }

      &-in {
        @include maxq(tablet) {
          width: 100% !important;
        }
      }

      .projects-render__tooltip-right-col {
        @include maxq(tablet) {
          background-color: $white !important;
          width: fit-content;
          padding: 0;
          border: 1px solid #e1e1e1;
          border-left: none;

          .projects-render__tooltip-arrow {
            width: 40px;
            height: 40px;
            &:nth-child(2) {
              transform: rotate(180deg);
            }
          }
        }

        @include maxq(mobile) {
          width: auto !important;
          flex-direction: column !important;
          justify-content: space-between;

          .projects-render__tooltip-arrow {
            border-right: none !important;
          }
        }
      }
    }
    &-left-col {
      min-width: 60px;
      margin-right: 64px;
      span {
        font-size: 12px;
        line-height: 18px;
        margin-top: 4px;
      }
      span,
      h3 {
        text-align: center;
      }
      @include maxq(desktop-2k) {
        margin-right: 32px;
      }
      @include maxq(desktop-lg) {
        margin-right: 24px;
      }
      @include maxq(tablet) {
        margin-right: 0px;
        padding: 16px 18px;
        border: 1px solid #e1e1e1;
      }
      @include maxq(mobile) {
        width: 100%;
        width: 84px;
      }
      @include maxq(mobile-sm) {
        padding: 5px;
      }
    }
    &-right-col {
      background-color: $disabled;
      padding: 32px;
      width: 349px;
      @include maxq(desktop-2k) {
        h3 {
          margin-right: 10px;
        }
        width: 262px;
      }
      @include maxq(desktop-lg) {
        padding: 24px;
        h3 {
          margin-right: 8px;
        }
        width: 210px;
      }
    }
  }
}

.type-view {
  width: max-content;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  &--top {
    top: 98px;

    @include maxq(desktop-2k) {
      top: 55px;
    }

    @include maxq(desktop-lg) {
      top: 20px;
    }

    @include maxq(mobile) {
      top: 78px;
    }
  }

  &--bottom {
    bottom: 20px;

    @include maxq(tablet) {
      bottom: 131px;
    }

    @include maxq(mobile) {
      bottom: 211px;
    }
  }
}

.loading-modal {
  position: absolute;

  .base-modal--wrapper {
    width: 132px;
    height: 132px;
    border-radius: 8px;
    justify-content: center;
  }

  .base-modal__content {
    min-height: auto;

    &-head {
      display: none;
    }
  }

  &__content {
    gap: 12px;

    @include maxq(tablet) {
      transform: scale(0.45);
    }

    span {
      padding-right: 10px;
    }

    svg {
      path:last-child {
        transform-origin: center;
        animation: loading 1.2s infinite;
      }
    }
  }

  .dot-flashing {
    margin-top: 10px;
    position: relative;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: $black;
    color: $black;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -6px;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: $black;
    color: $black;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 6px;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: $black;
    color: $black;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  .modal-backdrop {
    cursor: auto;
    width: 100%;
    background-color: $white;
  }
}
</style>
