<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */
import { computed } from "vue";
import { useBuildingStore } from "@/store/building";
import { humanReadableNum } from "@/composables/utils/template";

export interface Props {
  id: number | null;
  number: string | null;
  price: string | null;
  isSold: boolean | null;
  isReserved: boolean | null;
  hidePrice: boolean | null;
  space: string | null;
  route: string | null;
}

const props = defineProps<{
  data: Partial<Props>;
}>();

const buildingStore = useBuildingStore();

const currency = computed(() => buildingStore.currency.toLowerCase());
const price = computed(() =>
  props.data.price
    ? humanReadableNum(buildingStore.getPrice(Number(props.data.price), true))
    : buildingStore.getPrice(Number(props.data.price), true)
);
</script>

<template>
  <div
    class="flat-summary h-full w-full"
    :class="{ 'j-center': !data.number && !data.space }"
  >
    <div v-if="data.number" class="flat-summary__number">
      <p class="text d-flex j-center al-end">
        <span>№</span>
        {{ data.number }}
      </p>
      <div class="label uppercase text-center">{{ $t("apartment") }}</div>
    </div>
    <div
      class="flat-summary__price d-flex dir-col"
      :class="{ 'j-center': !price || data.hidePrice }"
    >
      <template v-if="!data.hidePrice">
        <p v-if="price" class="label uppercase text-center">{{ $t("price") }}</p>
        <p
          class="text text-center w-full"
          :title="
            price ? price?.toString() : data.isReserved ? $t('reserved') : $t('sold')
          "
        >
          <span class="price-text uppercase" v-if="data.isReserved">
            {{ $t("reserved") }}
          </span>
          <span class="price-text uppercase" v-else-if="data.isSold">
            {{ $t("sold") }}
          </span>
          <span :class="{ 'price-text': !price }" class="uppercase" v-else>
            {{ price || $t("sold") }}
          </span>

          <template v-if="price">
            <span class="price-currency" v-if="currency === 'usd'">$</span>
            <span class="price-currency" v-if="currency === 'eur'">€</span>
            <span class="price-currency" v-if="currency === 'gel'">₾</span>
            <!-- <DollarSign v-if="currency === 'usd'" />
            <EuroSign v-if="currency === 'eur'" />
            <LariSign v-if="currency === 'gel'" /> -->
          </template>
        </p>
      </template>
      <template v-else>
        <p class="text text-center w-full" :title="$t('hidden')">
          {{ $t("hidden") }}
        </p>
      </template>
    </div>
    <div v-if="data.space" class="flat-summary__space d-flex">
      <div class="label uppercase">{{ $t("area") }}</div>
      <div class="content">{{ data.space }} {{ $t("m") }} <sup>2</sup></div>
    </div>
  </div>
</template>

<style lang="scss">
.flat-summary-wrapper {
  min-width: 410px;

  @include maxq(desktop-2k) {
    min-width: 250px;
  }

  @include maxq(desktop-lg) {
    min-width: 225px;
  }

  @include maxq(tablet) {
    min-width: 186px;
    padding-bottom: 100px;
  }

  @include maxq(mobile) {
    min-width: unset;
  }

  &-in {
    min-height: 484px;

    @include maxq(desktop-2k) {
      min-height: 392px;
    }

    @include maxq(desktop-lg) {
      min-height: 277px;
    }

    @include maxq(tablet) {
      min-width: 256px;
      min-height: auto;
      justify-content: space-between;
    }
  }
}
.flat-summary {
  border: 1px solid $brightGray;
  background-color: #fff;
  padding: 40px;
  color: $black;
  display: flex;
  flex-direction: column;
  gap: 64px;

  @include maxq(desktop-2k) {
    padding: 30px;
    gap: 48px;
  }

  @include maxq(desktop-lg) {
    padding: 24px;
    gap: 24px;
  }

  // position: absolute;
  // bottom: 0;

  // left: 48px;
  // margin-right: 48px;

  @include maxq(tablet) {
    min-height: 82px;
    background-color: $disabled;
    position: relative;
    z-index: 1;
    padding: 16px 23px;
    flex-direction: row;
    border: none;
  }

  @include maxq(mobile) {
    background-color: $disabled;
    position: unset;
    display: flex;
    padding: 15px 14px;
    width: 100%;
    justify-content: space-between;

    &.j-center {
      justify-content: center;
    }
  }

  @include maxq(mobile-sm) {
    padding: 22px 8px;
  }

  .label {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 4px;

    @include maxq(desktop-2k) {
      font-size: 20px;
      line-height: 22px;
    }

    @include maxq(desktop-lg) {
      font-size: 12px;
      line-height: 14px;
    }

    @include maxq(tablet) {
      font-size: 12px;
      line-height: 11px;
      color: rgba($black, 0.6);
      margin-bottom: 0;
      margin-top: 4px;
      text-align: center;
    }

    @include maxq(mobile) {
      font-size: 12px;
      line-height: 11px;
      margin: 0;
    }

    @include maxq(mobile-sm) {
      font-size: 9px;
      margin-top: 0 !important;
    }
  }

  .content {
    @include maxq(tablet) {
      font-size: 16px;
      line-height: 17px;
    }
  }

  .text {
    font-size: 32px;
    line-height: 36px;

    @include maxq(desktop-2k) {
      font-size: 24px;
      line-height: 28px;
    }

    @include maxq(desktop-lg) {
      font-size: 20px;
      line-height: 26px;
    }

    @include maxq(mobile) {
      font-size: 20px;
      line-height: 18px;
    }
  }

  &__number {
    span {
      font-family: "Montserrat";
      font-size: 16px;
      line-height: 22px;
      position: relative;
      top: 2px;

      @include maxq(mobile) {
        font-size: 12px;
        line-height: 18px;
      }

      @include maxq(mobile-sm) {
        top: 0;
      }
    }

    .label {
      margin-bottom: 0;
      margin-top: 20px;

      @include maxq(desktop-2k) {
        margin-top: 10px;
      }

      @include maxq(desktop-lg) {
        margin-top: 4px;
      }
    }

    .text {
      @include weight(300);
      font-size: 50px;

      @include maxq(desktop-2k) {
        font-size: 30px;
      }

      @include maxq(desktop-lg) {
        font-size: 24px;
      }

      @include maxq(mobile) {
        position: relative;
        top: 0px;
      }

      svg {
        width: 40px;
        height: 24px;

        @include maxq(desktop-2k) {
          width: 30px;
        }

        @include maxq(desktop-lg) {
          width: 16px;
        }

        @include maxq(mobile) {
          position: relative;
          top: 3px;
        }
      }
    }

    @include maxq(tablet) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include maxq(mobile) {
      gap: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .text {
        font-size: 18px;
      }
    }

    @include maxq(mobile-sm) {
      gap: 0;

      .text {
        font-size: 14px;
      }
    }
  }

  &__price {
    white-space: nowrap;

    padding: 16px 24px;
    min-height: 98px;
    background-color: $disabled;

    @include maxq(desktop-lg) {
      min-height: 82px;
    }

    @include maxq(tablet) {
      min-height: auto;
    }

    .label {
      opacity: 0.6;

      @include maxq(desktop-lg) {
        margin-top: 4px;
      }
    }

    .text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include maxq(mobile) {
        line-height: 18px;
      }
    }

    .price-text {
      @include maxq(mobile) {
        line-height: 26px;
      }

      @include maxq(mobile-sm) {
        font-size: 9px;
      }
    }

    .price-currency {
      margin-left: 3px;
    }

    svg {
      width: 15px;
      margin-left: 4px;

      @include maxq(desktop-2k) {
        width: 12px;
      }

      @include maxq(desktop-lg) {
        width: 10px;
      }

      @include maxq(tablet) {
        height: 16px;
      }

      @include maxq(mobile) {
        position: relative;
        top: 0;
      }
    }

    @include maxq(tablet) {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      padding: 0;

      &.j-center {
        justify-content: center;
      }

      .text {
        font-weight: 300;
      }

      sup {
        position: absolute;
        top: -6px;
        right: -12px;
      }

      .label {
        opacity: 1;
        color: rgba($black, 0.6);
      }
    }

    @include maxq(mobile) {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      gap: 4px;

      .price-text {
        font-size: 12px;
      }
    }

    @include maxq(mobile-sm) {
      gap: 0;

      .text {
        font-size: 14px;
      }

      sup {
        top: -1px;
        right: -10px;
      }
    }
  }

  &__space {
    font-weight: 400;
    font-size: 32px;
    line-height: 20px;
    border: 1px solid $border-active;
    width: max-content;
    margin: 0 auto;
    padding: 26px;
    padding-right: 0;

    .content {
      padding: 5px;
      background-color: #fff;
      position: relative;
      right: -4px;
    }

    .label {
      display: none;
    }

    sup {
      right: 2px;
      position: relative;
    }

    @include maxq(desktop-2k) {
      font-size: 24px;
      padding: 20px;

      .content {
        padding: 3px;
        right: -23px;
      }
    }

    @include maxq(desktop-lg) {
      font-size: 16px;
      padding: 14px;

      .content {
        padding: 0;
        right: -18px;
      }
    }

    @include maxq(tablet) {
      padding: 0;
      border: none;
      flex-direction: column-reverse;
      justify-content: center;

      .content {
        font-weight: 300;
        background-color: transparent;
      }

      .label {
        display: block;
      }
    }

    @include maxq(mobile) {
      position: relative;
      padding: 0;
      margin: unset;
      border: unset;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 4px;

      .content {
        font-size: 18px;
        right: -4px;
        background-color: unset;
      }

      sup {
        position: absolute;
        top: -6px;
        right: -10px;
      }

      .label {
        display: flex;
        margin-top: 4px;
      }
    }

    @include maxq(mobile-sm) {
      gap: 0;

      .content {
        right: 0;
        font-size: 13px;
      }

      sup {
        right: -8px;
      }
    }
  }
}

html[lang="ka"] {
  .flat-summary-wrapper {
    width: 330px;

    @include maxq(desktop-lg) {
      width: 274px;
    }
  }
}

html[lang="ru"] {
  .flat-summary-wrapper {
    width: 380px;

    @include maxq(desktop-lg) {
      width: 320px;
    }
  }
}
</style>
