/** IMPORT HOOKS */
import { useMainStore } from "@/store/main";
import { ComputedRef, computed, onBeforeUnmount, onMounted, ref } from "vue";

/** IMPORT TYPES */
import type { Ref } from "vue";

export default (el: HTMLElement, defaultScaleVal: number = 2.5) => {
    const mainStore = useMainStore();
    /** REACTIVE DATA */
    const targetEl: Ref<HTMLElement> = ref(el);
    const targetParentEl: Ref<HTMLElement | null> = ref(null);
    targetParentEl.value = targetEl.value?.parentElement;
    /**
     * REQUEST ANIMATION FRAME VARIABLE
     */
    let reqId: number | null;
    // let lastDistance: number | null = null;

    const isGrabbed: Ref<boolean> = ref(false);
    const transLeftRef: Ref<number> = ref(0);
    const transTopRef: Ref<number> = ref(0);
    const scaleVal: Ref<number> = ref(defaultScaleVal);

    /**
     * HANDLE MOUSE DOWN EVENT
     */
    const touchStart = (ev: TouchEvent): void => {
        ev.preventDefault();
        if (!targetParentEl.value) return;

        coordz.clickX =
            ev?.touches?.[0]?.clientX -
            targetParentEl.value?.getBoundingClientRect().left -
            coordz.moveX;
        coordz.clickY =
            ev?.touches?.[0]?.clientY -
            targetParentEl.value?.getBoundingClientRect().top -
            coordz.moveY;
        isGrabbed.value = true;

        coordz.moveX =
            ev?.touches?.[0]?.clientX -
            targetParentEl.value.getBoundingClientRect().left -
            coordz.clickX;
        coordz.moveY =
            ev?.touches?.[0]?.clientY -
            targetParentEl.value.getBoundingClientRect().top -
            coordz.clickY;
    };

    // const detectPinchZoom = (ev: TouchEvent) => {
    //     const touches = ev.touches;
    //     if (touches.length !== 2) return;
    //     const distance = Math.hypot(
    //         touches[1].clientX - touches[0].clientX,
    //         touches[1].clientY - touches[0].clientY
    //     );

    //     if (lastDistance) {
    //         if (distance > lastDistance) {
    //
    //             if (scaleVal.value <= 2.7) scaleVal.value *= 1.05;
    //             // else scaleVal.value = 3.5;
    //         } else if (distance < lastDistance) {
    //
    //             if ((scaleVal.value /= 1.05) < 1) scaleVal.value = 1;
    //             else scaleVal.value /= 1.05;
    //         }
    //     }
    //     lastDistance = distance;
    // };

    /**
     * REMOVE GRAB ON MOUSE UP
     */
    const touchEnd = (ev: TouchEvent): void => {
        ev.preventDefault();
        isGrabbed.value = false;
        if (!targetParentEl.value) return;
        // coordz.clickX =
        //     ev?.touches?.[0]?.clientX -
        //     targetParentEl.value?.getBoundingClientRect().left;
        // coordz.clickY =
        //     ev?.touches?.[0]?.clientY -
        //     targetParentEl.value?.getBoundingClientRect().top;
    };

    /**
     * SET NEW COORDS ON MOUSE MOVE
     */
    const touchMove = (ev: TouchEvent): void => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!targetParentEl.value) return;
        isGrabbed.value = true;
        const leftLimit: number =
            (targetEl.value.offsetWidth * scaleVal.value -
                mainStore.windowWidth) /
            2;
        const topLimit: number =
            (targetEl.value.offsetHeight * scaleVal.value -
                targetEl.value.offsetHeight) /
            2;

        coordz.moveX =
            ev?.touches?.[0]?.clientX -
            targetParentEl.value.getBoundingClientRect().left -
            coordz.clickX;
        coordz.moveY =
            ev?.touches?.[0]?.clientY -
            targetParentEl.value.getBoundingClientRect().top -
            coordz.clickY;

        // detectPinchZoom(ev);

        if (coordz.moveX < 0 && Math.abs(coordz.moveX) > leftLimit) {
            coordz.moveX = -leftLimit;
        } else if (coordz.moveX > 0 && coordz.moveX > leftLimit) {
            coordz.moveX = leftLimit;
        }

        if (coordz.moveY < 0 && Math.abs(coordz.moveY) > topLimit) {
            coordz.moveY = -topLimit;
        } else if (coordz.moveY > 0 && coordz.moveY > topLimit) {
            coordz.moveY = topLimit;
        }
    };

    /**
     * REQUEST ANIMATION FRAME - MAKE SVG LAYER MOVE
     */
    const setReqAnimationFrame = () => {
        if (isGrabbed.value && scaleVal.value > 1) {
            targetEl.value.style.transform = `translate3d(${coordz.moveX}px, ${
                coordz.moveY
            }px, ${0}px) scale(${scaleVal.value})`;
        } else if (scaleVal.value === 1 && !isGrabbed.value) {
            targetEl.value.style.transform = `translate3d(${0}px, ${0}px, ${0}px) scale(${
                scaleVal.value
            })`;
        }
        reqId = requestAnimationFrame(setReqAnimationFrame);

        transLeftRef.value = coordz.moveX;
        transTopRef.value = coordz.moveY;
    };

    /**
     * GRABBED ELEMENT COORDINATES ACROSS THE DIV
     */
    const coordz: {
        clickX: number;
        clickY: number;
        moveX: number;
        moveY: number;
    } = {
        clickX: 0,
        clickY: 0,
        moveX: 0,
        moveY: 0,
    };

    // onMounted(() => {
    setReqAnimationFrame();
    targetEl.value?.addEventListener("touchstart", touchStart, false);
    targetEl.value?.addEventListener("touchmove", touchMove, false);

    targetEl.value?.addEventListener("touchend", touchEnd, false);

    // });

    onBeforeUnmount(() => {
        window.removeEventListener("touchstart", touchStart);
        targetEl.value.removeEventListener("touchmove", touchMove);
        window.removeEventListener("touchend", touchEnd);
        if (reqId !== null) cancelAnimationFrame(reqId);
        reqId = null;
    });
};
